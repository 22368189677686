import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Fullscreen click area`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.details-overlay`}</inlineCode>{` to expand the click area of `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` to cover the full screen, so user can click anywhere on a page to close `}<inlineCode parentName="p">{`<details>`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-details--overlay'
    }]} height="130" mdxType="StorybookEmbed" />
    <h2>{`Darkened fullscreen click area`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.details-overlay-dark`}</inlineCode>{` darken the click area overlay. Useful for modals.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-details--overlay-dark'
    }]} height="130" mdxType="StorybookEmbed" />
    <h2>{`Custom caret`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.details-reset`}</inlineCode>{` to remove the default caret (that little triangle on the left). You then can add the `}<inlineCode parentName="p">{`.dropdown-caret`}</inlineCode>{` on the right of the text.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-details--caret'
    }]} height="130" mdxType="StorybookEmbed" />
    <h2>{`Using button styles with the details summary element`}</h2>
    <p>{`You can add `}<inlineCode parentName="p">{`.btn`}</inlineCode>{` and `}<inlineCode parentName="p">{`.btn-*`}</inlineCode>{` classes to any
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary"
      }}><inlineCode parentName="a">{`<summary>`}</inlineCode></a>{`
element so that it gains the appearance of a button, and
selected/active styles when the parent
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details"
      }}><inlineCode parentName="a">{`<details>`}</inlineCode></a>{`
element is open.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-details--summary'
    }]} height="170" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      